<template>
  <page-container class="warehouse-send-records-list-container">
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
    >
      <van-list
          v-model="loading"
          :finished="finished"
      >
        <div
            v-for="item in dataList"
            class="records-info-item"
            :class="{ hasExpressInfo: hasExpressInfo(item.status) }"
            :key="item.id"
        >
          <div class="warehouse-goods-container">
            <goods-info
                view-model="small"
                :value="{
                goodsImgSrc: item.imageUrl,
                goodsName: item.goodsName,
                goodsDesc: '发货数量：' + item.count,
              }"
            />
            <div class="warehouse-goods-status">
              {{ WAREHOUSE_GOODS_STATUS_MAP[item.status] }}
            </div>
          </div>
          <div class="btn-container">
            <van-button
                v-if="hasExpressInfo(item.status)" size="small" round plain color="#666"
                @click.stop="handleRecordsClick(item)"
            >查看物流
            </van-button>
            <van-button
                v-if="canConfirmGoods(item.status)" size="small" round type="primary"
                @click.stop="handleConfirmGoods(item)"
            >确认收货
            </van-button>

          </div>
        </div>
        <van-empty
            v-if="!dataList.length && !loading"
            description="您还没有相关的订单"
        >
          <van-button round type="primary" @click="$router.back()"> 返回
          </van-button>
        </van-empty>
      </van-list>
    </van-pull-refresh>

    <van-overlay
        v-if="expressDialog.show"
        :show="true"
        @click="() => {
          !expressDialog.loading && (expressDialog.show = false)
        }"
        :lock-scroll="false"
    >
      <div v-show="!expressDialog.loading" style="overflow-y: auto;padding: 1em;max-height: 80vh">
        <express-info
            service-model="express"
            :record-id="expressDialog.recordId"
            :loading.sync="expressDialog.loading"
            @change="data => {
              this.expressDialog.show = !!data;
            }"
        />
      </div>

      <van-loading v-show="expressDialog.loading">查询中...</van-loading>

    </van-overlay>

  </page-container>
</template>

<script>
import { getWarehouseSendRecordsList, warehouseRecordReceiving } from '@/api/warehouse';
import GoodsInfo from '@/components/GoodsInfo';
import { Dialog } from 'vant';
import ExpressInfo from '@/components/ExpressInfo';
import { WAREHOUSE_GOODS_STATUS_MAP, YI_FA_HUO, YI_SHOU_HUO } from '@/const/warehouse';

export default {
  name: 'warehouseSendRecords',
  components: {
    ExpressInfo,
    GoodsInfo,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: false,
      dataList: [],
      expressDialog: {
        show: false,
        recordId: '',
        loading: true,
      },
    };
  },
  computed: {
    WAREHOUSE_GOODS_STATUS_MAP() {
      return WAREHOUSE_GOODS_STATUS_MAP;
    }
  },
  created() {
    this.onRefresh();
  },
  methods: {
    hasExpressInfo(status) {
      return [YI_FA_HUO, YI_SHOU_HUO].indexOf(status) > -1;
    },
    canConfirmGoods(status) {
      return status === YI_FA_HUO;
    },
    onRefresh() {
      // 清空列表数据
      this.dataList = [];
      this.loading = true;
      this.finished = this.refreshing;
      // 重新加载数据
      getWarehouseSendRecordsList().then(data => {
        this.loading = false;
        this.finished = true;
        this.refreshing = false;
        this.dataList = data || [];
      });
    },

    handleRecordsClick(item) {
      Object.assign(this.expressDialog, {
        show: true,
        recordId: item.id,
      });
    },
    handleConfirmGoods(item) {
      Dialog.confirm({
        title: `是否确认收货？`,
        // message: '弹窗内容',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            warehouseRecordReceiving({ id: item.id }).then(res => {
              if (res === true) {
                this.onRefresh();
                done();
              }
            });
          } else {
            done();
          }
        },
      }).catch(() => {
      });

    },
  },
};
</script>

<style lang="less" scoped>
.warehouse-send-records-list-container {
  @express-status-width: 80px;

  /deep/ .records-info-item {
    margin: 10px;
    padding: 12px;
    border-radius: 15px;
    overflow: hidden;
    background-color: #fff;

    .warehouse-goods-container {
      position: relative;
      padding: 0 @express-status-width 0 0;
    }

    .btn-container {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      align-items: center;
      box-sizing: border-box;
      margin-top: 20px;
      padding: 5px 0;
      //height: 46px;

      button {
        padding: 0 12px;
        font-size: 14px;
        margin: 5px 0 5px 10px;
      }
    }



    &.hasExpressInfo {
      //padding-right: @express-status-width;
    }

    .warehouse-goods-status {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: @express-status-width;
      height: 100%;
      line-height: 1;
      font-size: 13px;
      letter-spacing: 1px;
      color: @primary-color;
    }
  }

  /deep/ .goods-info-item {
    margin: 0;

    .goods-info-detail {
      margin-bottom: 0;

      .goods-desc {
        margin-top: 0;
        font-size: 14px;
        color: #666;
      }

      .goods-price {
        margin: 6px 0 0 0;
        line-height: 1;

        .price {
          font-size: 13px;

          &:before {
            content: none;
          }
        }
      }
    }
  }

  /deep/ .van-overlay {
    display: flex;
    justify-content: center;
    align-items: center;

    & > div {
      padding: 15px;
      width: 80%;
      max-height: 80%;
      background-color: #fff;
      border-radius: 15px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}
</style>
