// 仓库商品状态
export const SHEN_QING_FA_HUO = '01';
export const YI_FA_HUO = '02';
export const YI_SHOU_HUO = '03';

export const WAREHOUSE_GOODS_STATUS_LIST = [
  { id: SHEN_QING_FA_HUO, name: '申请发货' },
  { id: YI_FA_HUO, name: '已发货' },
  { id: YI_SHOU_HUO, name: '已收货' },
];

export const WAREHOUSE_GOODS_STATUS_MAP = [{}, ...WAREHOUSE_GOODS_STATUS_LIST].reduce((map, data) => {
  let { id, name } = data;
  map[id] = name;
  return map;
});
